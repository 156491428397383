<template>
  <div class="login d-flex justify-center align-center">
    <v-card class="login__card pa-7">
      <v-form ref="form" @submit.prevent="handleSubmit">
        <v-card-title class="pa-0">Login</v-card-title>
        <div class="caption">User email</div>
        <v-text-field
          class="login__card__input"
          v-model="userValue"
          outlined
          :error="loginFailed"
          :error-messages="errMsg"
          :rules="[rules.required]"
        ></v-text-field>
        <div class="caption">Password</div>
        <v-text-field
          class="login__card__input"
          type="password"
          v-model="passValue"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
        <v-btn type="submit" color="secondary">submit</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// internal
import { doLogin } from "@/utils/authUtils";
import { rules } from "@/utils/mixins";

export default {
  data() {
    return {
      loginFailed: false,
      errMsg: "",
      userValue: "",
      passValue: "",
    };
  },
  mixins: [rules],
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        doLogin(this.userValue, this.passValue)
          .then(() => {
            const toRoute = this.$route.query.to || "/";
            this.$router.push(toRoute);
          })
          .catch((err) => {
            this.errMsg = err.message;
            this.loginFailed = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  width: 100%;
  &__card {
    &__input {
      width: 300px;
    }
  }
}
</style>
