<template>
  <div class="home d-flex justify-center align-center">
    <v-fade-transition>
      <div
        v-if="showHomeInfo"
        class="home__inner d-flex flex-column align-center"
      >
        <img :src="jupiPlusLogo" />
        <div class="text-center pb-3">
          {{
            profileLock
              ? 'Please complete your profile to continue.'
              : showForbidden
              ? "You aren't authorized to access this page."
              : 'Use the menu at the top left to navigate.'
          }}
        </div>
        <v-btn
          v-if="profileLock"
          width="fit-content"
          height="38px"
          color="secondary"
          @click="handleProfileModal(true)"
        >
          <v-icon left>mdi-account</v-icon>Open Profile
        </v-btn>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
// internal
import jupiPlusLogo from '@/assets/logo.png';

export default {
  name: 'Home',
  data() {
    return {
      jupiPlusLogo,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'profileLock']),
    showHomeInfo() {
      return Object.keys(this.user ?? {}).length;
    },
    showForbidden() {
      return this.$route.path === '/403';
    },
  },
  methods: {
    ...mapActions('auth', ['handleProfileModal']),
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;

  &__inner {
    height: 500px;
    width: 500px;
  }
}
</style>
