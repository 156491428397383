import Vue from 'vue';
import VueRouter from 'vue-router';
// components
import Home from '@/views/Home';
import NotFound from '@/views/Home';
import Forbidden from '@/views/Home';
import AvailabilityCalendar from '@/views/AvailabilityCalendar';
import Login from '@/views/Login';
import Staff from '@/views/Staff';
import AssignmentDashboard from '@/views/AssignmentDashboard';
import Sourcing from '@/views/Sourcing';
import Firestore from '@/views/Firestore';
// internal
import { auth } from '@/providers/firebase';
import { ROUTES } from '@/utils/constants';
import store from '../store/index';

Vue.use(VueRouter);

const permCheck = (to, from, next) => {
  auth.currentUser.getIdTokenResult().then(() => {
    const userRoles = store.getters['auth/user'].roles;
    if (userRoles.includes('Admin')) next();
    else if (
      (to.meta.perm ? userRoles.includes(to.meta.perm) : true) &&
      !store.getters['auth/profileLock']
    )
      next();
    else permRejectHandler(auth.currentUser, next);
  });
};

const permRejectHandler = (user, next) => {
  if (user)
    next({ name: store.getters['auth/profileLock'] ? 'Home' : 'Forbidden' });
  else next({ name: 'Login' });
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/404',
    name: 'Not Found',
    component: NotFound,
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: Forbidden,
  },
  {
    path: `/${ROUTES.calendar}`,
    name: 'Availability Calendar',
    component: AvailabilityCalendar,
    beforeEnter: permCheck,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: `/${ROUTES.staff}`,
    name: 'Staff',
    component: Staff,
    meta: { perm: 'HR' },
    beforeEnter: permCheck,
  },
  {
    path: `/${ROUTES.assignmentDashboard}`,
    name: 'Tasks Dashboard',
    component: AssignmentDashboard,
    beforeEnter: permCheck,
  },
  {
    path: `/${ROUTES.sourcing}`,
    name: 'Sourcing',
    component: Sourcing,
    meta: { perm: 'HR' },
    beforeEnter: permCheck,
  },
  {
    path: `/${ROUTES.firestore}`,
    name: 'Firestore Commands',
    component: Firestore,
    meta: { perm: 'Admin' },
    beforeEnter: permCheck,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (window.location.pathname === '/login' || to.name === 'Login') {
    // go on as normal
    next();
  } else if (!auth.currentUser) {
    // redirect to login page
    const entry = to.fullPath;
    next({ name: 'Login', query: { to: entry } });
  } else {
    // go on as normal
    next();
  }
});

export default router;
