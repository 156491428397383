<template>
  <div class="pa-6">
    <v-btn color="primary" class="mr-2" @click="addStaff">
      <v-icon>mdi-account-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { processDocument, STAFF_COLLECTION_NAME } from '@/utils/dbUtils';

export default {
  name: 'Firestore',
  methods: {
    async addStaff() {
      const response = await processDocument({
        document: {
          access: true,
          altContacts: '',
          availability: 'Full-time',
          bday: '',
          card: '',
          country: 'Ukraine',
          cvLink: '',
          cvRef: false,
          docsLink: '',
          email: 'bohdan.vn.ua@gmail.com',
          emergency: '',
          engLevel: 'B2',
          firstName: '',
          hobbies: '',
          homeAddress: '',
          isPe: true,
          lastName: '',
          legalName: '',
          linkedIn: '',
          personalEmail: '',
          phoneNumber: '',
          photoRef: false,
          postAddress: '',
          preferredName: '',
          pronouns: '',
          rate: '',
          roles: ['Admin'],
          slackId: '',
          specialty: '',
          startDate: '',
          website: '',
        },
        collectionName: STAFF_COLLECTION_NAME,
      });
      console.log(response);
    },
  },
};
</script>

<style lang="scss" scoped></style>
