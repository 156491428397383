<template>
  <div class="sourcing my-10 px-2">
    <div class="d-flex justify-space-between" :style="{ position: 'relative' }">
      <!-- left -->
      <div class="d-flex">
        <!-- new candidate -->
        <div class="text-center">
          <span class="overline"> New </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              :loading="dropdownsLoading"
              @click="handleForm({})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- form -->
        <v-dialog max-width="1200" :value="sourcingModal" persistent>
          <SourcingForm
            :specialties="specialties"
            :experienceRange="experienceRange"
            :techLevels="techLevels"
            :skills="skills"
            :statuses="statuses"
            :engLevelOptions="engLevelOptions"
            :itemToEdit="itemToEdit"
            @sourcing-table-refresh="handleRefresh"
            @close-form="handleModal"
          />
        </v-dialog>

        <!-- clear filters -->
        <div class="text-center ml-2">
          <span class="overline"> Clear </span>
          <div class="mb-4">
            <v-btn
              :min-width="laptop ? '40px' : '42px'"
              height="38px"
              class="elevation-2 px-0"
              color="secondary"
              @click="clearFilters"
            >
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- specialty filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Specialty </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="sourcing__main-selector selector"
              :class="{ laptop }"
              :items="specialties"
              v-model="filterSpecialty"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Specialty', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- exp filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Years of Experience </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="sourcing__secondary-selector selector"
              :class="{ laptop }"
              :items="experienceRange"
              v-model="filterExperience"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('Experience', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- tech lvl filter -->
        <div class="ml-lg-2 ml-md-1 text-center">
          <span class="overline"> Tech Level </span>
          <div class="d-flex mb-4">
            <v-autocomplete
              class="sourcing__secondary-selector selector"
              :class="{ laptop }"
              :items="techLevels"
              v-model="filterTechLevel"
              solo
              dense
              hide-details
              :loading="dropdownsLoading"
              @input="handleFilterQuery('TechLevel', $event)"
            ></v-autocomplete>
          </div>
        </div>

        <!-- skill filter -->
        <transition>
          <div
            v-show="laptop ? !expandMenu : true"
            class="ml-lg-2 ml-md-1 text-center"
          >
            <span class="overline"> Skills </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                class="sourcing__main-selector selector"
                :class="{ laptop }"
                :items="skills"
                v-model="filterSkills"
                multiple
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Skills', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>

        <!-- status filter -->
        <transition>
          <div v-show="!expandMenu" class="ml-lg-2 ml-md-1 text-center">
            <span class="overline"> Status </span>
            <div class="d-flex mb-4">
              <v-autocomplete
                class="sourcing__main-selector selector"
                :class="{ laptop }"
                :items="statuses"
                v-model="filterStatus"
                solo
                dense
                hide-details
                :loading="dropdownsLoading"
                @input="handleFilterQuery('Status', $event)"
              ></v-autocomplete>
            </div>
          </div>
        </transition>
      </div>

      <!-- right -->
      <div class="d-flex flex-column justify-start align-end my-0">
        <!-- search -->
        <span class="overline" :style="{ opacity: 0, userSelect: 'none' }"
          >Search</span
        >
        <div class="mb-4">
          <v-badge
            :content="laptopSmaller ? '...' : filterSearchBadge"
            :value="filterSearch && !expandMenu"
            color="secondary"
            overlap
          >
            <v-btn
              height="38px"
              min-width="48px"
              class="elevation-2 px-0"
              outlined
              color="primary"
              @click="expandMenu = !expandMenu"
            >
              <v-icon v-if="expandMenu">mdi-magnify-minus-outline</v-icon>
              <v-icon v-else>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-expand-x-transition>
            <v-text-field
              :style="{ position: 'absolute', bottom: '16px', right: '60px' }"
              v-show="expandMenu"
              v-model="filterSearch"
              class="sourcing__search-selector"
              solo
              dense
              hide-details
              clearable
            />
          </v-expand-x-transition>
        </div>
      </div>
    </div>
    <!-- table -->
    <v-card>
      <v-skeleton-loader
        v-if="showTableLoader || dropdownsLoading"
        type="table"
        height="100vh"
      ></v-skeleton-loader>
      <SourcingTable
        v-if="tableData && !showTableLoader && !dropdownsLoading"
        :tableData="tableData"
        :filterSearch="filterSearch"
        :filterSpecialty="filterSpecialty ? filterSpecialty : ''"
        :filterExperience="filterExperience ? filterExperience : ''"
        :filterTechLevel="filterTechLevel ? filterTechLevel : ''"
        :filterSkills="filterSkills"
        :filterStatus="filterStatus ? filterStatus : ''"
        @call-edit-form="handleForm"
      />
    </v-card>
  </div>
</template>

<script>
// libraries
import _ from 'lodash';
// internal
import {
  getAllCollectionItems,
  SOURCING_COLLECTION_NAME,
} from '@/utils/dbUtils';
import { customBreakpoint, timeUnits } from '@/utils/mixins';
import { ROUTES } from '@/utils/constants';
// components
import SourcingTable from '@/components/Sourcing/SourcingTable';
import SourcingForm from '@/components/Sourcing/SourcingForm';

export default {
  name: 'Sourcing',
  components: {
    SourcingTable,
    SourcingForm,
  },
  data() {
    return {
      showTableLoader: false,
      tableData: null,
      filterSearch: '',
      expandMenu: false,
      dropdownsLoading: false,
      specialties: [
        'Front end',
        'Back end',
        'Full stack',
        'Mobile',
        'Project Management',
        'Sales',
        'Design',
        'QA',
      ],
      filterSpecialty: '',
      experienceRange: ['N/A', '< 1', '1 - 2', '2 - 3', '3 - 5', '> 5'],
      filterExperience: '',
      techLevels: [
        'Trainee',
        'Junior',
        'Junior Strong',
        'Middle',
        'Middle Strong',
        'Senior',
      ],
      filterEngLevel: '',
      engLevelOptions: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      filterTechLevel: '',
      skills: [
        '.NET',
        'ACF',
        'ASP.NET',
        'AWS',
        'Ably',
        'ActiveAdmin',
        'AdonisJS',
        'Agile',
        'Angular',
        'Ansible',
        'Ant Design',
        'Apollo',
        'Asterisk',
        'Azure',
        'Backbone.js',
        'BankID',
        'Blockchain',
        'Bootstrap',
        'BullMQ',
        'C',
        'C#',
        'C++',
        'CI/CD',
        'Capybara',
        'Chai',
        'ClickHouse',
        'Cloud services',
        'CloudFlare',
        'CoffeeScript',
        'Corezoid',
        'Cucumber',
        'Cypress',
        'Divi',
        'Django',
        'Docker',
        'E2E testing',
        'Elasticsearch',
        'Electron.js',
        'Elementor',
        'Elixir',
        'Entity',
        'Enzyme',
        'Ethereum',
        'Ethers.js',
        'Express.js',
        'Fastify',
        'Firebase',
        'Flutter',
        'Formik',
        'GORM',
        'Gatsby',
        'GetStream.io',
        'Gin',
        'Go',
        'Google Cloud',
        'GraphQL',
        'Gutenberg',
        'Hotwire',
        'Integration testing',
        'Ionic',
        'Jade',
        'Jasmine',
        'Java',
        'JavaScript',
        'Jenkins',
        'Jest',
        'Joi',
        'Kafka',
        'Karma',
        'Kibana',
        'Knex',
        'Koa',
        'Kotlin',
        'Kubernetes',
        'LESS',
        'Laravel',
        'Linux',
        'LoopBack',
        'Lua',
        'MQTT',
        'MSSQL',
        'MUI',
        'MacOS',
        'Magento',
        'MariaDB',
        'Marionette.js',
        'Maven',
        'Median.co',
        'Microservices',
        'Minio',
        'Mocha',
        'MongoDB',
        'Mongoose',
        'MySQL',
        'NFT',
        'NestJs',
        'Next.js',
        'NoSQL',
        'Node.js',
        'Nuxt.js',
        'Objective‑C',
        'OpenAI',
        'OpenCart',
        'Oracle',
        'PHP',
        'Phoenix',
        'Pinia',
        'PixiJS',
        'Playwright',
        'PostgreSQL',
        'Prisma',
        'Puppeteer',
        'Python',
        'Qt',
        'RSpec',
        'RTK query',
        'RabbitMQ',
        'React',
        'React Native',
        'Redis',
        'Ruby',
        'Ruby on Rails',
        'Rust',
        'S3',
        'SASS',
        'SEO',
        'SQL',
        'SQL',
        'SQLite',
        'Scala',
        'Scrapy',
        'Selenium',
        'Sentry',
        'Sequelize',
        'Sidekiq',
        'Sockets',
        'Solidity',
        'Spring',
        'Storybook',
        'Strapi',
        'Stripe',
        'Supabase',
        'Supertest',
        'Svelte',
        'Swift',
        'Symfony',
        'Tailwind',
        'TestCafe',
        'Tomcat',
        'Twilio',
        'TypeORM',
        'TypeScript',
        'Unit testing',
        'Unity',
        'Veeva Vault',
        'Vue.js',
        'Vuetify',
        'WPF',
        'Web3',
        'WebRTC',
        'WebSocket',
        'WinForms',
        'WordPress',
        'Yup',
        'Zustand',
        'gRPC',
        'iOS',
      ],
      filterSkills: [],
      statuses: [
        'Sourced',
        'Screening Scheduled',
        'Screening Passed',
        'Tech Interview Scheduled',
        'Tech Interview Passed',
        'Negotiating',
        'Rejected',
        'Hired',
      ],
      filterStatus: '',
      sourcingModal: false,
      itemToEdit: {},
    };
  },
  computed: {
    filterSearchBadge() {
      return this.filterSearch?.length <= 6
        ? this.filterSearch
        : `${this.filterSearch?.slice(0, 6)}...`;
    },
  },
  mixins: [customBreakpoint, timeUnits],
  watch: {
    filterSearch(newValue) {
      const currentQuery = this.$route.query;
      const newQuery =
        newValue !== ''
          ? {
              ...currentQuery,
              Search: newValue,
            }
          : _.omit(currentQuery, ['Search']);

      this.$router
        .push({ path: `/${ROUTES.sourcing}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler
    },
  },
  mounted() {
    this.handleQuery();
    this.getTableData();
    // this.handleDropdowns();
  },
  methods: {
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    handleDropdowns() {
      Promise.all([
        getClients(),
        getEnum('ProjectStatus'),
        getEnum('ProjectType'),
        getEnum('Checklist'),
        getEnum('Priority'),
        getAllStaff(),
      ])
        .then((resp) => {
          [
            this.clients,
            this.statuses,
            this.types,
            this.checklistItems,
            this.priorities,
            this.staffList,
          ] = resp;
          this.DRIs =
            resp[5].filter(
              (el) =>
                el.user.userRole === 'ADMIN' || el.user.userRole === 'MANAGER'
            ) ?? [];
          this.dropdownsLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.dropdownsLoading = false;
        });
    },
    getTableData() {
      this.showTableLoader = true;
      getAllCollectionItems(SOURCING_COLLECTION_NAME).then((data) => {
        this.tableData =
          data?.status === 500
            ? []
            : data.map((el) => ({ ...el, cvDownloading: false }));
        this.showTableLoader = false;
      });
    },
    handleFilterQuery(param, data) {
      const currentQuery = this.$route.query;

      const newQuery = {
        ...currentQuery,
        [param]: data,
      };
      this.$router
        .push({ path: `/${ROUTES.sourcing}`, query: newQuery })
        .catch(() => {}); // Avoided redundant navigation error handler

      this[`filter${param}`] = data;
    },
    clearFilters() {
      this.filterSpecialty =
        this.filterExperience =
        this.filterTechLevel =
        this.filterSearch =
        this.filterStatus =
          '';
      this.filterSkills = [];

      this.$router.push({ path: `/${ROUTES.sourcing}` }).catch(() => {}); // Avoided redundant navigation error handler
    },
    handleQuery() {
      const currentQuery = this.$route.query;

      if (!_.isEmpty(currentQuery))
        Object.keys(currentQuery).forEach(
          (param) => (this[`filter${param}`] = currentQuery[param])
        );
    },
    handleModal() {
      this.sourcingModal = !this.sourcingModal;
    },
    handleForm(tableRow) {
      this.handleModal();
      this.itemToEdit = { ...tableRow };
    },
    handleRefresh() {
      this.getTableData();
      this.handleModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.sourcing {
  max-width: 1500px;
  margin: 0 auto;

  &__main-selector {
    width: 250px;
    &.laptop {
      width: 200px;
    }
  }

  &__secondary-selector {
    width: 180px;
    &.laptop {
      width: 130px;
    }
  }

  &__search-selector {
    width: 340px;
  }
}

::v-deep {
  .selector.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding-right: 0;
  }
  .selector.v-text-field.v-text-field--solo .v-input__append-inner {
    padding-left: 0;
  }
  .selector.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
  .selector.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 2px 0;
  }
  .filter-checkbox .v-input--selection-controls__input {
    margin-right: 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'lg-and-down')} {
  .v-application .overline {
    font-size: 0.6rem !important;
  }
}
</style>
