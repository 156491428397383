<template>
  <div>
    <div class="calendar ma-auto d-flex justify-end">
      <v-btn
        v-if="isFCStaff"
        small
        color="secondary"
        dark
        class="mt-1 mb-0"
        @click="handleDefaultCapacity"
      >
        <v-icon class="mr-2">mdi-account-edit</v-icon>Default Capacity</v-btn
      >
    </div>
    <v-card
      class="calendar ma-auto elevation-3"
      :class="clientView ? 'form-view' : 'mt-2 mb-10'"
    >
      <v-sheet
        tile
        height="54"
        class="d-flex justify-space-between align-center"
      >
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="text-h4">{{ currentPage }}</span>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet height="600">
        <v-skeleton-loader
          v-if="showCalendarLoader"
          type="table-tbody@2"
        ></v-skeleton-loader>
        <v-calendar
          v-if="!showCalendarLoader"
          ref="calendar"
          v-model="value"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          type="month"
          :events="events"
          @change="getCurrentPage($event)"
          @click:date="clientView ? null : markUnavailable($event)"
          @click:event="clientView ? null : editUnavailabilityRecord($event)"
        ></v-calendar>
      </v-sheet>
      <v-row justify="center">
        <v-dialog
          v-model="unavailabilityRecord"
          width="950px"
          @click:outside="handleUnavailabilityRecordClosing"
          @keydown.esc="handleUnavailabilityRecordClosing"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="v-card v-sheet theme--light"
          >
            <v-row
              v-if="editMode"
              class="pa-6 ma-0 align-center justify-space-between"
            >
              <span v-if="tab == 0" class="text-h5">{{ formMode[0] }}</span>
              <span v-if="tab == 1" class="text-h5">{{ formMode[1] }}</span>
              <v-btn
                class="mx-2"
                fab
                small
                color="secondary"
                @click="deleteUnavailabilityRecord"
                :loading="deletionProcessing"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-row>
            <v-tabs
              v-else
              v-model="tab"
              background-color="transparent"
              grow
              class="pa-4 pb-8"
            >
              <v-tab v-for="mode in formMode" :key="mode">
                {{ mode }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="px-3">
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="unavailabilityDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="unavailabilityDatesRange"
                            label="Date(s) unavailable"
                            prepend-icon="mdi-calendar-remove"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="
                              unavailabilityDatesValidation
                                ? null
                                : dateValidationError
                            "
                            :rules="tab == 0 ? [rules.required] : []"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="unavailabilityDates"
                          range
                          :rules="tab == 0 ? [rules.required] : []"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-combobox
                        ref="comboBox"
                        outlined
                        label="Note"
                        :items="suggestedNotes"
                        v-model="note"
                        :rules="tab == 0 ? [rules.required] : []"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        v-model="limitedCapacityDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="limitedCapacityDatesRange"
                            label="Date(s) capacity limited"
                            prepend-icon="mdi-calendar-remove"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="
                              limitedCapacityDateValidation
                                ? null
                                : dateValidationError
                            "
                            :rules="tab == 1 ? [rules.required] : []"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="limitedCapacityDates"
                          range
                          :rules="tab == 1 ? [rules.required] : []"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-combobox
                        ref="limitedCapacityBox"
                        outlined
                        label="Note"
                        :items="suggestedNotes"
                        v-model="limitedCapacityNote"
                        :rules="tab == 1 ? [rules.required] : []"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        label="LOC Translating"
                        type="number"
                        v-model="localization"
                        :rules="tab == 1 && !editing ? [rules.required] : []"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        label="LOC Editing"
                        type="number"
                        v-model="editing"
                        :rules="
                          tab == 1 && !localization ? [rules.required] : []
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions class="pa-6 pt-4 justify-end">
              <v-btn
                width="30%"
                color="secondary"
                :disabled="
                  userNotFound ||
                  (tab == 0 && !unavailabilityDatesValidation) ||
                  (tab == 1 && !limitedCapacityDateValidation)
                "
                @click="handleUnavailabilityRecord"
                :loading="creationProcessing || updatingProcessing"
              >
                {{ btnTxt }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="defaultCapacity" width="fit-content">
          <v-form
            ref="defaultCapacityForm"
            v-model="defaultCapacityValid"
            lazy-validation
            class="v-card v-sheet theme--light"
          >
            <v-card-title class="justify-space-between py-4">
              <span class="text-h5">Default Workload</span>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field
                      outlined
                      label="LOC Translating Daily Workload"
                      type="number"
                      v-model="localizationDefaultCapacity"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" class="pb-0">
                    <v-text-field
                      outlined
                      label="LOC Editing Daily Workload"
                      type="number"
                      v-model="editingDefaultCapacity"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-end px-6 py-4">
              <v-btn
                width="30%"
                color="secondary"
                :disabled="userNotFound"
                @click="handleDefaultCapacityUpdate"
                :loading="confirmationProcessing"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-dialog>
      </v-row>
      <v-alert v-model="userNotFound" type="error">
        Your email can't be found in company's database. Please log in with your
        work email or contact Project Manager in case of issues with access.
      </v-alert>
    </v-card>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from "vuex";
// internal
import {
  processEvent,
  getEvents,
  deleteEvent,
  processUser,
  getAllStaff,
} from "@/utils/dbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "Calendar",
  props: {
    clientView: {
      type: Boolean,
      required: false,
      default: false,
    },
    employee: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      userNotFound: false,
      showCalendarLoader: false,
      value: "",
      currentPage: "",
      events: [],
      valid: true,
      unavailabilityRecord: false,
      unavailabilityDateMenu: false,
      unavailabilityDates: [],
      note: null,
      limitedCapacityDateMenu: false,
      limitedCapacityDates: [],
      limitedCapacityNote: null,
      localization: null,
      editing: null,
      tab: 0,
      creationProcessing: false,
      editMode: false,
      recordID: null,
      updatingProcessing: false,
      deletionProcessing: false,
      defaultCapacity: false,
      defaultCapacityValid: true,
      localizationDefaultCapacity: null,
      editingDefaultCapacity: null,
      confirmationProcessing: false,
      dateValidationError: "End date can't be before start date",
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  methods: {
    ...mapActions("flashMessage", ["handleFlash"]),
    ...mapActions("auth", ["setUser"]),
    getCalendarData() {
      if (this.user.user.email) {
        this.showCalendarLoader = true;
        getEvents({
          user: this.clientView ? this.employee : this.user.user,
        }).then((data) => {
          this.events = data.map((el) => ({
            name:
              !!Number(el.adjustedDailyLocalization) ||
              !!Number(el.adjustedDailyEditing)
                ? "Limited Workload"
                : el.note,
            start: this.$moment(el.startDate).format("YYYY-MM-DD"),
            end: this.$moment(el.endDate).format("YYYY-MM-DD"),
            color:
              el.note === "Time off"
                ? "#ADE3ED"
                : el.note === "At capacity (can't accept new tasks)"
                ? "#EBA79B"
                : el.note === "National holiday"
                ? "#CAF2BF"
                : el.note === "Sick/personal day"
                ? "#F2E9AE"
                : "#DBD5D0",
            note: el.note,
            localization: el.adjustedDailyLocalization,
            editing: el.adjustedDailyEditing,
            id: el.id,
          }));
          this.showCalendarLoader = false;
        });
      } else {
        this.userNotFound = true;
      }
    },
    getCurrentPage(date) {
      this.currentPage = `${this.$moment(date.start.month + "", "M").format(
        "MMMM"
      )} ${this.$moment(date.start.year + "").format("YYYY")}`;
    },
    markUnavailable(value) {
      this.$refs?.form?.reset();
      this.unavailabilityDates.push(value.date);
      this.limitedCapacityDates = [];
      this.note =
        this.limitedCapacityNote =
        this.localization =
        this.editing =
          null;
      this.unavailabilityRecord = true;
    },
    handleUnavailabilityRecord() {
      if (this.note || this.tab == 0) this.$refs.comboBox.blur();
      if (this.limitedCapacityNote || this.tab == 1)
        this.$refs.limitedCapacityBox.blur();
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.creationProcessing = true;
          const data = {
            adjustedDailyEditing: this.tab == 0 ? 0 : this.editing,
            adjustedDailyLocalization: this.tab == 0 ? 0 : this.localization,
            endDate:
              this.tab == 0
                ? this.unavailabilityDates[1]
                  ? `${this.unavailabilityDates[1]}T12:00:00`
                  : `${this.unavailabilityDates[0]}T12:00:00`
                : this.limitedCapacityDates[1]
                ? `${this.limitedCapacityDates[1]}T12:00:00`
                : `${this.limitedCapacityDates[0]}T12:00:00`,
            note: this.tab == 0 ? this.note : this.limitedCapacityNote,
            personName: {
              firstName: this.user.user.firstName,
              lastName: this.user.user.lastName,
            },
            startDate:
              this.tab == 0
                ? `${this.unavailabilityDates[0]}T12:00:00`
                : `${this.limitedCapacityDates[0]}T12:00:00`,
          };

          if (this.editMode) Object.assign(data, { id: this.recordID });

          processEvent({ data, staffId: this.user.user.id }).then((resp) => {
            this.creationProcessing = false;
            if (resp?.status === 200) {
              if (this.tab == 0)
                publishMessage({
                  user: this.user.adminDetails.slackIdPm
                    ? `<@${this.user.adminDetails.slackIdPm}>`
                    : `*${this.user.user.firstName} ${this.user.user.lastName}*`,
                  mode: "availability",
                  data: {
                    from: this.unavailabilityDates[0],
                    to: this.unavailabilityDates[1],
                    note: this.note,
                  },
                });
              this.getCalendarData();
              this.handleUnavailabilityRecordClosing();
            }
            this.handleFlash({
              response: resp,
              show: true,
            });
          });
        }
      }, 100);
    },
    handleUnavailabilityRecordClosing() {
      this.unavailabilityRecord =
        this.editMode =
        this.creationProcessing =
          false;
      this.unavailabilityDates = [];
      this.limitedCapacityDates = [];
    },
    editUnavailabilityRecord(record) {
      this.editMode = true;
      if (record.event.localization || record.event.editing) {
        this.tab = 1;
        record.event.start === record.event.end
          ? this.limitedCapacityDates.push(record.event.start)
          : this.limitedCapacityDates.push(
              record.event.start,
              record.event.end
            );
        this.limitedCapacityNote = record.event.note;
        this.localization = record.event.localization;
        this.editing = record.event.editing;
      } else {
        this.tab = 0;
        record.event.start === record.event.end
          ? this.unavailabilityDates.push(record.event.start)
          : this.unavailabilityDates.push(record.event.start, record.event.end);
        this.note = record.event.note;
      }
      this.recordID = record.event.id;
      this.unavailabilityRecord = true;
    },
    deleteUnavailabilityRecord() {
      this.deletionProcessing = true;
      deleteEvent(this.recordID).then((resp) => {
        this.deletionProcessing = false;
        this.getCalendarData();
        this.handleUnavailabilityRecordClosing();
        this.handleFlash({ response: resp, show: true });
      });
    },
    handleDefaultCapacity() {
      this.localizationDefaultCapacity =
        this.user.adminDetails.defaultDailyLocalization;
      this.editingDefaultCapacity = this.user.adminDetails.defaultDailyEditing;
      this.defaultCapacity = true;
    },
    handleDefaultCapacityUpdate() {
      if (this.$refs.defaultCapacityForm.validate()) {
        this.confirmationProcessing = true;
        const data = {
          defaultDailyLocalization: Number(this.localizationDefaultCapacity),
          defaultDailyEditing: Number(this.editingDefaultCapacity),
        };
        processUser({
          data,
          id: this.user.user.id,
          defaultCapacity: true,
        }).then((resp) => {
          if (resp?.status === 200) {
            getAllStaff({ id: this.user.user.id }).then((resp) => {
              this.setUser(resp);
              publishMessage({
                user: this.user.adminDetails.slackIdFc
                  ? `<@${this.user.adminDetails.slackIdFc}>`
                  : `*${this.user.user.firstName} ${this.user.user.lastName}*`,
                mode: "defaultCapacity",
                data: {
                  locDefault: this.localizationDefaultCapacity,
                  editDefault: this.editingDefaultCapacity,
                },
              });
            });
            this.confirmationProcessing = false;
            this.defaultCapacity = false;
            this.handleFlash({ response: resp, show: true });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    unavailabilityDatesRange: {
      get: function () {
        return this.unavailabilityDates
          .map((date) => this.formatPickerDate(date))
          .join(" - ");
      },
      set: function (newVal) {
        return (
          this.formatPickerDate(newVal)
            ? [...this.formatPickerDate(newVal)]
            : []
        ).join(" - ");
      },
    },
    isFCStaff() {
      // return (
      //   !!this.user["LastDeliveryDate-Editor"] ||
      //   !!this.user["LastDeliveryDate-Writer"]
      // );
      return !this.clientView; // needs Staff - Project relation
    },
    formMode() {
      return this.isAdmin || this.isManager || this.isFCStaff
        ? ["Unavailability Record", "Limited Capacity"]
        : ["Unavailability Record"];
    },
    unavailabilityDatesValidation() {
      return this.unavailabilityDates[1]
        ? this.$moment(this.unavailabilityDates[0]).isSameOrBefore(
            this.$moment(this.unavailabilityDates[1])
          )
        : true;
    },
    limitedCapacityDatesRange: {
      get: function () {
        return this.limitedCapacityDates
          .map((date) => this.formatPickerDate(date))
          .join(" - ");
      },
      set: function (newVal) {
        return (
          this.formatPickerDate(newVal)
            ? [...this.formatPickerDate(newVal)]
            : []
        ).join(" - ");
      },
    },
    limitedCapacityDateValidation() {
      return this.limitedCapacityDates[1]
        ? this.$moment(this.limitedCapacityDates[0]).isSameOrBefore(
            this.$moment(this.limitedCapacityDates[1])
          )
        : true;
    },
    suggestedNotes() {
      let notes = ["Time off", "At capacity (can't accept new tasks)"];
      if (this.isManager || this.isAdmin)
        notes.push("Sick/personal day", "National holiday");
      return notes;
    },
    btnTxt() {
      return this.editMode ? "Update" : "Save";
    },
  },
  mixins: [timeUnits],
  mounted() {
    this.getCalendarData();
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  max-width: 1400px;
  &.form-view {
    height: 708px;
  }
}
::v-deep {
  .v-alert__wrapper {
    justify-content: center;
  }
  .v-alert__content {
    flex: unset;
  }
  .v-event-summary {
    color: #000 !important;
  }
}
</style>
