import { auth, functions } from "@/providers/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { DOMAIN } from "@/utils/constants";

export const doLogin = async (email, pw) => {
  const u = await signInWithEmailAndPassword(auth, email, pw);
  if (u.user) return u;
};

export const doLogout = async () => {
  try {
    await auth.signOut();
  } catch (err) {
    return err;
  }
};

export const createNewUserWithPasswordSendEmail = async (props) => {
  try {
    const createUser = functions.httpsCallable("createNewUser"),
      addClaims = functions.httpsCallable("addClaims"),
      sendEmail = functions.httpsCallable("sendEmail"),
      password = window.crypto
        .getRandomValues(new BigUint64Array(1))[0]
        .toString(36),
      newUserData = { ...props, password };

    await createUser(newUserData);
    await addClaims(newUserData);

    const emailText = `<p>Hello and welcome to JUPI PLUS Office. Your login details are below:</p><p>Username: ${newUserData.email}<br/>Password: ${newUserData.password}</p><p>You can access the office at <a href="https://${DOMAIN}">https://${DOMAIN}</a>.</p><p>If you'd like to change your password, you can do so by logging into the app and selecting "Reset Password" after clicking your email in the top right of the screen.</p><p>Thanks,<br/>JUPI PLUS</p>`;
    sendEmail({
      from: "JUPI PLUS HR <hr@jupiplus.com>",
      to: newUserData.email,
      subject: "Welcome to JUPI PLUS Office app",
      // text: this.message,
      html: emailText,
    });
  } catch (err) {
    return err;
  }
};
