<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="First Name"
            v-model="candidate.firstName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-text-field
            outlined
            label="Last Name"
            v-model="candidate.lastName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Status"
            :items="statuses"
            v-model="candidate.status"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="pb-0">
          <v-autocomplete
            outlined
            label="Specialty"
            :items="specialties"
            v-model="candidate.specialty"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="LinkedIn"
            v-model="candidate.linkedIn"
          />
        </v-col>
        <v-col cols="12" sm="3" class="pb-0">
          <v-file-input
            outlined
            label="CV"
            accept=".pdf"
            truncate-length="20"
            v-model="cvBlob"
            :loading="cvLoading"
            :rules="[rules.isPdf]"
            @change="processCvRef($event)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="English Level"
            :items="engLevelOptions"
            v-model="candidate.eng"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Years of Experience"
            :items="experienceRange"
            v-model="candidate.experience"
            :rules="[rules.required]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="pb-0">
          <v-autocomplete
            outlined
            label="Technical Level"
            :items="techLevels"
            v-model="candidate.techLevel"
            :rules="candidate.specialty === 'Sales' ? [] : [rules.required]"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            outlined
            multiple
            chips
            deletable-chips
            label="Skills"
            :items="skills"
            :search-input.sync="skillSearchInput"
            v-model="candidate.skills"
            :rules="
              candidate.specialty === 'Sales' ? [] : [rules.requiredArray]
            "
            @change="handleSkillInput"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="3"
            clear-icon="mdi-close-circle-outline"
            label="Notes"
            v-model="candidate.notes"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteCandidate">
            Yes
          </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="candidateHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="candidateHandling"
        :disabled="deleteConfirmation"
        @click="$emit('close-form')"
      >
        Close
      </v-btn>
      <v-btn
        color="secondary"
        :loading="candidateHandling"
        :disabled="deleteConfirmation"
        @click="handleCandidate"
      >
        {{ isEditMode ? 'Update' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import {
  processDocument,
  deleteDocument,
  SOURCING_COLLECTION_NAME,
  getFileBlob,
  uploadFile,
  deleteFile,
} from '@/utils/dbUtils';
import { rules } from '@/utils/mixins';

export default {
  name: 'SourcingForm',
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    specialties: {
      type: Array,
      required: true,
    },
    experienceRange: {
      type: Array,
      required: true,
    },
    techLevels: {
      type: Array,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    engLevelOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      skillSearchInput: '',
      candidate: {
        firstName: null,
        lastName: null,
        linkedIn: null,
        specialty: null,
        eng: null,
        experience: null,
        techLevel: null,
        skills: [],
        notes: null,
        cvRef: false,
        status: null,
      },
      processFile: false,
      cvLoading: false,
      cvBlob: null,
      valid: true,
      candidateHandling: false,
      deleteConfirmation: false,
    };
  },
  mixins: [rules],
  computed: {
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.dataViewsHandler();
  },
  methods: {
    async dataViewsHandler() {
      this.deleteConfirmation = false;
      this.candidate = { ...this.itemToEdit };
      if (!this.isEditMode) {
        await this.$refs.form.reset();
        this.candidate.cvRef = false;
        this.candidate.status = 'Sourced';
      }
      if (this.candidate.cvRef) this.handleCv();
      else this.cvBlob = null;
    },
    handleSkillInput() {
      this.skillSearchInput = '';
    },
    handleCv() {
      this.cvLoading = true;
      getFileBlob(`${this.itemToEdit.id}_cvFile`).then((blob) => {
        let newBlob = new Blob([blob], {
          type: 'application/pdf',
        });
        newBlob.name = `${this.candidate.firstName} ${this.candidate.lastName}'s CV`;
        this.cvBlob = newBlob;
        this.cvLoading = false;
      });
    },
    processCvRef(file) {
      this.processFile = true;
      this.candidate.cvRef = !!file;
    },
    async handleCandidate() {
      this.candidateHandling = true;
      if (this.$refs.form.validate()) {
        if (this.processFile && this.itemToEdit?.id) {
          if (this.candidate.cvRef)
            await uploadFile({
              file: this.cvBlob,
              fileName: `${this.itemToEdit.id}_cvFile`,
            });
          else {
            if (this.itemToEdit.cvRef)
              await deleteFile(`${this.itemToEdit.id}_cvFile`);
          }
        }
        processDocument({
          document: this.candidate,
          collectionName: SOURCING_COLLECTION_NAME,
        }).then(async (id) => {
          if (!this.itemToEdit?.id && !!this.cvBlob)
            await uploadFile({
              file: this.cvBlob,
              fileName: `${id}_cvFile`,
            });
          this.candidateHandling = false;
          this.$emit('sourcing-table-refresh');
        });
      } else this.candidateHandling = false;
    },
    async deleteCandidate() {
      this.deleteConfirmation = false;
      this.candidateHandling = true;
      if (this.itemToEdit.cvRef)
        await deleteFile(`${this.itemToEdit.id}_cvFile`);
      deleteDocument({
        docId: this.itemToEdit.id,
        collectionName: SOURCING_COLLECTION_NAME,
      }).then(() => {
        this.candidateHandling = false;
        this.$emit('sourcing-table-refresh');
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
