var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{class:{ 'row-click': _vm.isAdminMode },attrs:{"headers":_vm.headers,"items":_vm.filteredData,"item-key":"id","header-props":{ sortIcon: null },"show-expand":"","group-by":"dueDate","group-desc":"","sort-by":"name","items-per-page":50,"footer-props":{
      'items-per-page-options': [10, 50, 100, -1],
    }},on:{"click:row":function($event){return _vm.handleEditForm($event)}},scopedSlots:_vm._u([{key:`group.header`,fn:function({ group, headers }){return [_c('td',{staticClass:"pa-0 group-header-row",attrs:{"colspan":headers.length}},[_c('h2',{staticClass:"mx-4 font-weight-bold d-inline"},[_vm._v(" "+_vm._s(`Due Date: ${_vm.formatDate(group)}`)+" ")]),(_vm.handleDuesStats(group))?_c('v-chip',{attrs:{"color":"font-weight-bold white","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-clock-alert-outline ")]),_vm._v(_vm._s(_vm.handleDuesStats(group))+" ")],1):_vm._e()],1)]}},{key:`item.fullLogs`,fn:function({ item }){return [(item.estimate)?_c('v-progress-circular',{staticClass:"my-1",attrs:{"rotate":360,"size":60,"width":8,"value":_vm.getTimeUsed(item),"color":"primary"}},[_vm._v(" "+_vm._s(`${_vm.getTimeUsed(item)}%`)+" ")]):_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.getTimeUsed(item)))])]}},{key:`item.id`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","icon":"","fab":"","outlined":"","color":"primary","elevation":"1","disabled":item.estimate ? _vm.getTimeUsed(item) >= 100 : false},on:{"click":[function($event){return _vm.$emit('call-log-form', {}, item)},function($event){$event.stopPropagation();}]}},[_c('v-icon',[_vm._v("mdi-timer-plus")])],1)]}},{key:`item.name`,fn:function({ value }){return [_c('v-tooltip',{attrs:{"right":"","color":"primary","disabled":value?.length <= 20},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.ellipseText(value, 20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value))])])]}},{key:`item.assignee`,fn:function({ value }){return [_vm._v(" "+_vm._s(`${value.firstName} ${value.lastName}`)+" ")]}},{key:`item.dueTime`,fn:function({ item, value }){return [_c('v-badge',{attrs:{"value":_vm.handleDueAlert(item),"color":_vm.handleDueAlert(item) === 'overdue'
            ? '#E57373'
            : _vm.handleDueAlert(item) === 'upcoming-due'
            ? '#FFB74D'
            : '',"icon":"mdi-clock-alert-outline"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:`item.estimate`,fn:function({ value }){return [(value)?_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(_vm._s(value))]):_vm._e()]}},{key:`item.status`,fn:function({ item, value }){return [_c('ADStatusSelector',{attrs:{"item":item,"value":value},on:{"refresh-table-data":_vm.updateTableDataStatus}})]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('ADTableExpansion',_vm._g({attrs:{"taskInfo":item}},_vm.$listeners))],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }