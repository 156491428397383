<template>
  <div>
    <v-app-bar class="app-bar" app color="primary" dark>
      <div class="d-flex align-center">
        <v-expand-x-transition>
          <v-app-bar-nav-icon
            v-if="showMenuButton && !profileLock"
            @click.stop="drawer = !drawer"
          />
        </v-expand-x-transition>
        <v-toolbar-title>
          <span :style="{ fontWeight: 700 }">JUPI PLUS Office</span
          ><span class="ml-2" :style="{ fontWeight: 200 }">v 0.1.0</span>
        </v-toolbar-title>
        <h2
          v-if="$route.name !== 'Home' && $route.name !== 'Login'"
          class="ml-10"
        >
          {{ $route.name }}
        </h2>
      </div>
      <NavBarUser />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="nav-drawer-container"
      id="nav-drawer-container"
    >
      <v-list class="list" nav dense>
        <!-- ///////// TEAM TOOLS /////////// -->
        <v-list-group :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2">
                  Team Tools
                </v-chip></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item
            @click="handleListSelect(`${routesConst.assignmentDashboard}`)"
          >
            <v-list-item-title> Tasks Dashboard </v-list-item-title>
          </v-list-item>
          <!--  -->
          <!-- <v-list-item @click="handleListSelect(`${routesConst.calendar}`)">
            <v-list-item-title> Availability Calendar </v-list-item-title>
          </v-list-item> -->
        </v-list-group>
        <!-- ///////// HR TOOLS /////////// -->
        <v-list-group v-if="isAdmin || isHr" :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="v-subheader">
                <v-chip class="font-weight-bold white elevation-2">
                  HR Tools
                </v-chip></v-list-item-title
              >
            </v-list-item-content>
          </template>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.sourcing}`)">
            <v-list-item-title> Sourcing </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item @click="handleListSelect(`${routesConst.staff}`)">
            <v-list-item-title> Staff </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// internal
import { ROUTES } from '@/utils/constants';
// components
import NavBarUser from '@/components/ui/NavBarUser';

export default {
  components: { NavBarUser },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    handleListSelect(value) {
      this.drawer = false;
      this.$router.push(value);
    },
  },
  computed: {
    ...mapGetters('auth', ['user', 'profileLock', 'isAdmin', 'isHr']),
    showMenuButton() {
      return Object.keys(this.user ?? {}).length;
    },
    routesConst() {
      return ROUTES;
    },
  },
};
</script>

<style lang="scss" scoped>
#nav-drawer-container {
  height: 100vh !important;
}
.v-list {
  .v-list-item {
    padding: 0px 20px !important;
  }
}
::v-deep {
  .v-list-item__icon {
    margin: 0 !important;
    align-self: unset;
    color: rgba(0, 0, 0, 0.6);
    i {
      font-size: 30px;
    }
  }
}
</style>
