export const authModule = {
  state: () => ({
    user: {},
    profileModal: false,
  }),
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    HANDLE_PROFILE_MODAL(state, profileModal) {
      state.profileModal = profileModal;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit('SET_USER', user);
    },
    handleProfileModal(context, profileModal) {
      context.commit('HANDLE_PROFILE_MODAL', profileModal);
    },
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    profileModal: (state) => {
      return state.profileModal;
    },
    profileLock: (state) => {
      const requiredFields = [
        'altContacts',
        'bday',
        'card',
        'cvRef',
        'emergency',
        'engLevel',
        'firstName',
        'hobbies',
        'homeAddress',
        'lastName',
        'legalName',
        'personalEmail',
        'phoneNumber',
        'photoRef',
        'preferredName',
        'pronouns',
      ];
      return state.user?.roles?.includes('Admin')
        ? false
        : !requiredFields.every((field) => state.user?.[field]);
    },
    isAdmin: (state) => {
      return state.user?.roles?.includes('Admin');
    },
    isHr: (state) => {
      return state.user?.roles?.includes('HR');
    },
    isSales: (state) => {
      return state.user?.roles?.includes('Sales');
    },
    isPm: (state) => {
      return state.user?.roles?.includes('PM');
    },
  },
};
