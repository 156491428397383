import { db } from '@/providers/firebase';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  orderBy,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getBlob,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
// vuex
import store from '@/store/index';

//// CONSTANTS ////
export const SOURCING_COLLECTION_NAME = 'sourcing';
export const STAFF_COLLECTION_NAME = 'staff';
export const TASKS_COLLECTION_NAME = 'tasks';
export const LOGS_COLLECTION_NAME = 'logs';

const showFlashMsg = (error) =>
  store._actions['flashMessage/handleFlash'][0]({
    message: error?.message ?? null,
    success: !error,
    show: true,
  });

const extractDocsData = (collectionItems) => {
  let collectionItemsMap = [];
  collectionItems.forEach((document) =>
    collectionItemsMap.push({
      id: document.id,
      ...document.data(),
    })
  );
  return collectionItemsMap;
};

export const toTitleCase = (key) => {
  const titleCase = key.replace(/([A-Z])/g, ' $1');
  return titleCase.charAt(0).toUpperCase() + titleCase.slice(1);
};

//// FILES ////

const storage = getStorage();

export const uploadFile = async ({ file, fileName }) => {
  try {
    const storageRef = ref(storage, fileName);
    await uploadBytes(storageRef, file);
  } catch (e) {
    showFlashMsg(e);
  }
};

export const deleteFile = async (fileName) => {
  try {
    const fileRef = ref(storage, fileName);
    await deleteObject(fileRef);
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getFileBlob = async (fileName) => {
  try {
    const fileRef = ref(storage, fileName);
    const blob = await getBlob(fileRef);
    return blob;
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getFirebasePdfUrl = async (fileName) => {
  try {
    const fileRef = ref(storage, fileName);
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
  } catch (e) {
    showFlashMsg(e);
    return null;
  }
};

//// ALL COLLECTIONS ////

export const getAllCollectionItems = async (collectionName) => {
  try {
    const collectionItems = await getDocs(collection(db, collectionName));
    return extractDocsData(collectionItems);
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getCollectionItemByParams = async ({
  collectionName,
  param,
  value,
}) => {
  try {
    const locationsCollectionRef = collection(db, collectionName);
    const filteredQuery = query(
      locationsCollectionRef,
      where(param, '==', value)
    );
    const collectionItems = await getDocs(filteredQuery);
    return extractDocsData(collectionItems);
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getAllSortedCollectionItems = async (
  collectionName,
  orderByField
) => {
  try {
    const collectionRef = collection(db, collectionName);
    const collectionItems = await getDocs(
      query(collectionRef, orderBy(orderByField))
    );
  } catch (e) {
    showFlashMsg(e);
  }
};

export const processDocument = async ({ document, collectionName }) => {
  try {
    if (document?.id) {
      const docRef = doc(db, collectionName, document.id);
      await updateDoc(docRef, document);
      showFlashMsg();
    } else {
      const doc = await addDoc(collection(db, collectionName), document);
      showFlashMsg();
      return doc.id;
    }
  } catch (e) {
    showFlashMsg(e);
  }
};

export const deleteDocument = async ({ docId, collectionName }) => {
  try {
    const docRef = doc(db, collectionName, docId);
    await deleteDoc(docRef);
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getIdQuantity = async (id, collectName) => {
  try {
    const locationsCollectionRef = collection(db, collectName);
    const filteredQuery = query(
      locationsCollectionRef,
      where('candidateId', '>=', id),
      where('candidateId', '<=', id + '\uf8ff')
    );
    let collectionItems = await getDocs(filteredQuery);
    return collectionItems.size;
  } catch (e) {
    showFlashMsg(e);
  }
};

//// LOCATIONS ////

export const addLocation = async (location) => {
  try {
    await addDoc(collection(db, LOCATIONS_COLLECTION_NAME), {
      ...location,
    });
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

export const updateLocation = async (location) => {
  try {
    const docRef = doc(db, LOCATIONS_COLLECTION_NAME, location.id);
    await updateDoc(docRef, location);
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getLocationSortedCollectionItems = async (collectionName) => {
  try {
    const locationsCollectionRef = collection(db, collectionName);
    const filteredQuery = query(locationsCollectionRef);
    let collectionItemsMapName = [];
    let collectionItemsMapId = [];
    let collectionItems = await getDocs(filteredQuery);
    collectionItems.forEach((document) => {
      collectionItemsMapName.push(document.data().locationName);
      collectionItemsMapId.push(document.data().locationId);
    });
    return { collectionItemsMapName, collectionItemsMapId };
  } catch (e) {
    showFlashMsg(e);
  }
};

export const validateFromFB = async (collectionName, id) => {
  try {
    const locationsCollectionRef = collection(db, collectionName);
    const filteredQuery = query(
      locationsCollectionRef,
      where('locationId', '==', id)
    );
    let collectionItems = await getDocs(filteredQuery);
    return !!collectionItems.size;
  } catch (e) {
    showFlashMsg(e);
  }
};

//// FACTORY RECRUITMENT ////

export const processFactoryRecruitmentRequest = async (data) => {
  try {
    if (data?.id) {
      const docRef = doc(db, FACTORY_COLLECTION_NAME, data.id);
      await updateDoc(docRef, data);
    } else await addDoc(collection(db, FACTORY_COLLECTION_NAME), data);
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

export const checkNicNum = async (nicNum) => {
  try {
    const locationsCollectionRef = collection(db, FACTORY_COLLECTION_NAME);
    const filteredQuery = query(
      locationsCollectionRef,
      where('personalDetails.nicNumber', '==', nicNum)
    );
    let collectionItemsMap = [];
    let collectionItems = await getDocs(filteredQuery);
    collectionItems.forEach((document) =>
      collectionItemsMap.push({
        ...document.data(),
      })
    );
    return collectionItemsMap;
  } catch (e) {
    showFlashMsg(e);
  }
};

//// OFFICE RECRUITMENT ////

export const processOfficeRecruitmentRequest = async (data) => {
  try {
    if (data?.id) {
      const docRef = doc(db, OFFICE_COLLECTION_NAME, data.id);
      await updateDoc(docRef, data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
    } else {
      const doc = await addDoc(collection(db, OFFICE_COLLECTION_NAME), data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
      return doc.id;
    }
  } catch (e) {
    showFlashMsg(e);
  }
};

export const getOfficeCandidateCollectionItems = async (candidateId) => {
  try {
    const locationsCollectionRef = collection(db, OFFICE_COLLECTION_NAME);
    const filteredQuery = query(
      locationsCollectionRef,
      where('id', '==', candidateId)
    );
    const collectionItem = await getDocs(filteredQuery);
    return collectionItem.docs[0].data();
  } catch (e) {
    showFlashMsg(e);
  }
};

//// COMPANY VALUES QUESTIONS ////

export const addValuesQuestion = async (question) => {
  try {
    await addDoc(collection(db, VALUES_QUESTIONS_COLLECTION_NAME), {
      ...question,
    });
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

export const updateValuesQuestion = async (question) => {
  try {
    const docRef = doc(db, VALUES_QUESTIONS_COLLECTION_NAME, question.id);
    await updateDoc(docRef, question);
    showFlashMsg();
  } catch (e) {
    showFlashMsg(e);
  }
};

//// JOB ROLES ////

export const processJobRole = async (data) => {
  try {
    if (data?.id) {
      const docRef = doc(db, JOB_ROLES_COLLECTION_NAME, data.id);
      await updateDoc(docRef, data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
    } else {
      await addDoc(collection(db, JOB_ROLES_COLLECTION_NAME), data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
    }
  } catch (e) {
    showFlashMsg(e);
  }
};

//// NEW EMPLOYEE ////

export const processNewEmployee = async (data) => {
  try {
    if (data?.id) {
      const docRef = doc(db, NEW_EMPLOYEE_REQUEST_COLLECTION_NAME, data.id);
      await updateDoc(docRef, data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
    } else {
      await addDoc(collection(db, NEW_EMPLOYEE_REQUEST_COLLECTION_NAME), data);
      store._actions['flashMessage/handleFlash'][0]({
        message: null,
        success: true,
        show: true,
      });
      // return doc.id;
    }
  } catch (e) {
    showFlashMsg(e);
  }
};
