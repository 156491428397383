<template>
  <div>
    <Calendar v-if="activeBtnIndex === 0" />
    <AdminCalendar v-else />
    <v-bottom-navigation
      v-if="isAdmin || isManager"
      :value="activeBtnIndex"
      color="secondary"
      fixed
    >
      <v-btn @click="activeBtnIndex = 0">
        <span>Personal</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn @click="activeBtnIndex = 1">
        <span>All Staff</span>
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// components
import Calendar from "@/components/AvailabilityCalendar/Calendar";
import AdminCalendar from "@/components/AvailabilityCalendar/AdminCalendar";

export default {
  name: "AvailabilityCalendar",
  components: {
    Calendar,
    AdminCalendar,
  },
  data() {
    return { activeBtnIndex: 0 };
  },
  computed: {
    ...mapGetters("auth", ["isAdmin", "isManager"]),
  },
};
</script>

<style></style>
