export const rules = {
  data() {
    return {
      rules: {
        required: (value) => !!value || value === 0 || 'Required',
        requiredArray: (value) => !!value.length || 'Required',
        requiredFile: (value) =>
          (value ? !!(value?.size + '') : !!value) || 'Required',
        isPdf: (value) =>
          value?.type == 'application/pdf' ||
          value === null ||
          'Only PDF attachments are allowed.',
        isImage: (value) =>
          value?.type == 'image/jpeg' ||
          value?.type == 'image/jpg' ||
          value?.type == 'image/png' ||
          value === null ||
          'Only JPEG (JPG) or PNG images are allowed.',
        validEmail: (value) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          'E-mail must be valid',
      },
    };
  },
};

export const customBreakpoint = {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    laptop() {
      return this.windowWidth < 1500;
    },
    laptopSmaller() {
      return this.windowWidth < 1280;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};

export const timeUnits = {
  data() {
    return {
      monthOptions: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      yearOptions: Array.from(
        { length: 20 },
        (_, i) => `${new Date().getFullYear() + 1 - i}`
      ),
    };
  },
};
