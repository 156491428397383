<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light pa-4"
  >
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="log.date"
                :rules="[rules.required]"
                label="Date worked"
                prepend-icon="mdi-calendar-clock"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="isAdmin ? null : new Date(Date.now()).toISOString()"
              v-model="log.date"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            outlined
            label="Amount of hours"
            type="number"
            v-model="log.time"
            :rules="
              parent.estimate
                ? [rules.required, customRules.logValidation]
                : [rules.required]
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-textarea
            outlined
            clearable
            rows="3"
            clear-icon="mdi-close-circle-outline"
            label="Comment"
            v-model="log.comment"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-end py-0">
      <v-scale-transition>
        <div v-if="deleteConfirmation" class="d-flex align-center">
          <h3>Are you sure?</h3>
          <v-btn class="ml-2" color="success" @click="deleteLog"> Yes </v-btn>
          <v-btn class="ml-2" color="error" @click="deleteConfirmation = false">
            No
          </v-btn>
        </div>
      </v-scale-transition>
      <v-btn
        v-if="isEditMode"
        min-width="unset"
        class="ml-2"
        color="secondary"
        :loading="logHandling"
        :disabled="deleteConfirmation"
        @click="deleteConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        :loading="logHandling"
        :disabled="deleteConfirmation"
        @click="handleLog"
      >
        {{ isEditMode ? 'Update' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// internal
import {
  processDocument,
  deleteDocument,
  LOGS_COLLECTION_NAME,
} from '@/utils/dbUtils';
import { rules } from '@/utils/mixins';

export default {
  name: 'LogForm',
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateMenu: false,
      log: {
        date: null,
        time: null,
        comment: null,
      },
      valid: true,
      logHandling: false,
      deleteConfirmation: false,
      customRules: {
        logValidation: (value) =>
          value <=
            +this.parent.estimate +
              (+this.itemToEdit?.time || 0) -
              this.parent.fullLogs.reduce((a, b) => +a + +(b.time || 0), 0) ||
          `You can't log more than remaining ${
            +this.parent.estimate +
            (+this.itemToEdit?.time || 0) -
            this.parent.fullLogs.reduce((a, b) => +a + +(b.time || 0), 0)
          } hr(s) in this task.`,
      },
    };
  },
  mixins: [rules],
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  mounted() {
    this.dataViewsHandler();
  },
  methods: {
    async dataViewsHandler() {
      this.deleteConfirmation = false;
      this.log = { ...this.itemToEdit };
      if (!this.isEditMode) await this.$refs.form.reset();
    },
    async handleLog() {
      this.logHandling = true;
      if (this.$refs.form.validate()) {
        await processDocument({
          document: { ...this.log, parent: this.log?.parent ?? this.parent.id },
          collectionName: LOGS_COLLECTION_NAME,
        });
        this.logHandling = false;
        this.$emit('tasks-table-refresh');
      } else this.logHandling = false;
    },
    async deleteLog() {
      this.deleteConfirmation = false;
      this.logHandling = true;
      await deleteDocument({
        docId: this.itemToEdit.id,
        collectionName: LOGS_COLLECTION_NAME,
      });
      this.logHandling = false;
      this.$emit('tasks-table-refresh');
    },
  },
};
</script>

<style lang="scss" scoped></style>
