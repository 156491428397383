export const DOMAIN =
  process.env.VUE_APP_NODE_ENV === 'production'
    ? 'https://office.jupiplus.com'
    : process.env.VUE_APP_NODE_ENV === 'staging'
    ? 'https://jupi-plus-office.netlify.app/'
    : 'http://localhost:8080';

export const ROUTES = {
  calendar: 'calendar',
  assignmentDashboard: 'tasks-dashboard',
  staff: 'staff',
  clients: 'clients',
  projects: 'projects',
  sourcing: 'sourcing',
  firestore: 'firestore',
};

export const STATUS_COLORS = [
  {
    statuses: ['To Do'],
    value: '#EAD1DC',
  },
  {
    statuses: ['Done'],
    value: '#B6D7A8',
  },
  {
    statuses: ['In Progress'],
    value: '#D9D2E9',
  },
  {
    statuses: ['In Testing'],
    value: '#91c5f3',
  },
  {
    statuses: ['Ready For Testing'],
    value: '#fffbab',
  },
  {
    statuses: ['On Hold'],
    value: '#bebebe',
  },
  {
    statuses: ['Reopened'],
    value: '#ffbaba',
  },
];
