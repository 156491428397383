<template>
  <div class="pa-6">
    <v-row class="text-left">
      <!-- left column -->
      <v-col cols="12" sm="6">
        <div class="overline">
          {{ taskInfo.project }}
        </div>
        <h2>{{ taskInfo.name }}</h2>
        <v-chip class="my-5" color="primary">
          {{ taskInfo.estimate ? `${taskInfo.estimate} hr(s)` : 'Outstaff' }}
        </v-chip>
        <div>
          <!-- desc -->
          <v-row>
            <v-col cols="12" sm="3" class="py-1 font-weight-bold"
              >Description</v-col
            >
            <v-col class="py-1" cols="12" sm="9">{{ taskInfo.desc }}</v-col>
          </v-row>
        </div>
      </v-col>
      <!-- right column -->
      <v-col cols="12" sm="6">
        <div class="table-bg text-center">
          <h3 class="pb-2">Work Logs</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Date</th>
                  <th class="text-center">Time</th>
                  <th class="text-center">Comment</th>
                  <th class="text-center">Edit/Delete</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="log in fullLogs" :key="log.id">
                  <td>{{ log.date }}</td>
                  <td>
                    {{ log.time }}
                  </td>
                  <td>
                    {{ log.comment }}
                  </td>
                  <td>
                    <v-btn
                      small
                      icon
                      fab
                      outlined
                      color="primary"
                      elevation="1"
                      @click="$emit('edit-log', log, taskInfo)"
                      @click.stop
                    >
                      <v-icon>mdi-timer-sync</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td><b>Logged:</b></td>
                  <td>
                    <b>
                      {{
                        `${fullLogs.reduce(
                          (a, b) => +a + +(b.time || 0),
                          0
                        )} hrs`
                      }}</b
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ADTableExpansion',
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullLogs() {
      return this.taskInfo.fullLogs.sort((a, b) =>
        b.date.localeCompare(a.date)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-bg {
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
}
</style>
