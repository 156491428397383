export const assignmentModule = {
  state: () => ({
    statusOptions: [],
    projectsList: ['Air Platter', 'Internal', 'Outbidda', 'RXNT'],
    staffList: [],
  }),
  mutations: {
    SET_COMMON_DATA(state, { field, data }) {
      state[field] = [...data];
    },
  },
  actions: {
    setCommonData(context, { field, data }) {
      context.commit('SET_COMMON_DATA', { field, data });
    },
  },
  getters: {
    statusOptions: (state) => {
      return state.statusOptions;
    },
    projectsList: (state) => {
      return state.projectsList;
    },
    staffList: (state) => {
      return state.staffList;
    },
  },
};
