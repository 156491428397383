import axios from 'axios';

export const sendInvoices = async ({ reportsData, usdRate, payPeriod }) => {
  reportsData.forEach((r) => {
    r.receivers.forEach(async (receiver) => {
      const messageData = {
        channel: receiver,
        preview: 'Salary report is available!',
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*${r.assignee.firstName} ${
                r.assignee.lastName
              } - salary report for ${payPeriod}*\n\n${r.projects
                .map(
                  (p) =>
                    `${Object.entries(p)[0][0]} - ${
                      Object.entries(p)[0][1]
                    } hr(s)`
                )
                .join('\n')}\n\n*Total:* (${r.projects
                .map((p) => Object.values(p)[0])
                .reduce((a, b) => a + b, 0)}h x ${r.assignee.rate}$/h = ${(
                r.projects
                  .map((p) => Object.values(p)[0])
                  .reduce((a, b) => a + b, 0) * r.assignee.rate
              ).toFixed(2)}$) x ${usdRate}₴ (NBU) = ${(
                r.projects
                  .map((p) => Object.values(p)[0])
                  .reduce((a, b) => a + b, 0) *
                r.assignee.rate *
                usdRate
              ).toFixed(2)}₴`,
            },
          },
        ],
      };

      try {
        await axios.post(
          `${
            process.env.VUE_APP_NODE_ENV === 'production'
              ? `https://${
                  process.env.VUE_APP_NODE_ENV === 'staging'
                    ? 'jupi-plus.onrender'
                    : 'www.jupiplus'
                }.com`
              : 'http://localhost:3000'
          }/api/publish-message`,
          messageData
        );
      } catch (err) {
        return err.response;
      }
    });
  });
};
