<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="id"
      :header-props="{ sortIcon: null }"
      show-expand
      group-by="dueDate"
      group-desc
      sort-by="name"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, -1],
      }"
      :class="{ 'row-click': isAdminMode }"
      @click:row="handleEditForm($event)"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers }">
        <td class="pa-0 group-header-row" :colspan="headers.length">
          <h2 class="mx-4 font-weight-bold d-inline">
            {{ `Due Date: ${formatDate(group)}` }}
          </h2>
          <v-chip
            v-if="handleDuesStats(group)"
            color="font-weight-bold white"
            small
          >
            <v-icon left> mdi-clock-alert-outline </v-icon
            >{{ handleDuesStats(group) }}
          </v-chip>
        </td>
      </template>

      <!-- Time Used cell override -->
      <template v-slot:[`item.fullLogs`]="{ item }">
        <v-progress-circular
          v-if="item.estimate"
          :rotate="360"
          :size="60"
          :width="8"
          :value="getTimeUsed(item)"
          color="primary"
          class="my-1"
        >
          {{ `${getTimeUsed(item)}%` }}
        </v-progress-circular>
        <v-chip v-else color="primary">{{ getTimeUsed(item) }}</v-chip>
      </template>

      <!-- Log Work cell override -->
      <template v-slot:[`item.id`]="{ item }">
        <v-btn
          small
          icon
          fab
          outlined
          color="primary"
          elevation="1"
          :disabled="item.estimate ? getTimeUsed(item) >= 100 : false"
          @click="$emit('call-log-form', {}, item)"
          @click.stop
        >
          <v-icon>mdi-timer-plus</v-icon>
        </v-btn>
      </template>

      <!-- Detail cell override -->
      <template v-slot:[`item.name`]="{ value }">
        <v-tooltip right color="primary" :disabled="value?.length <= 20">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ ellipseText(value, 20) }}</span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>

      <!-- Assignee cell override -->
      <template v-slot:[`item.assignee`]="{ value }">
        {{ `${value.firstName} ${value.lastName}` }}
      </template>

      <!-- Due time override -->
      <template v-slot:[`item.dueTime`]="{ item, value }">
        <v-badge
          :value="handleDueAlert(item)"
          :color="
            handleDueAlert(item) === 'overdue'
              ? '#E57373'
              : handleDueAlert(item) === 'upcoming-due'
              ? '#FFB74D'
              : ''
          "
          icon="mdi-clock-alert-outline"
        >
          {{ value }}
        </v-badge>
      </template>

      <!-- Estimate cell override -->
      <template v-slot:[`item.estimate`]="{ value }">
        <v-chip v-if="value" color="secondary">{{ value }}</v-chip>
      </template>

      <!-- Status cell override -->
      <template v-slot:[`item.status`]="{ item, value }">
        <ADStatusSelector
          :item="item"
          :value="value"
          @refresh-table-data="updateTableDataStatus"
        />
      </template>

      <!-- Expanded panel -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ADTableExpansion v-on="$listeners" :taskInfo="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// vuex
import { mapGetters } from 'vuex';
// libraries
import Fuse from 'fuse.js';
// components
import ADTableExpansion from './ADTableExpansion.vue';
import ADStatusSelector from './ADStatusSelector.vue';

export default {
  name: 'ADTable',
  components: {
    ADTableExpansion,
    ADStatusSelector,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    isAdminMode: {
      type: Boolean,
      required: true,
    },
    filterPaymentMonth: {
      required: false,
    },
    filterPaymentYear: {
      required: false,
    },
    filterDeliveryDate: {
      required: false,
    },
    filterSearch: {
      required: false,
    },
    filterProject: {
      required: false,
    },
    filterPerson: {
      required: false,
    },
    filterStatus: {
      required: false,
    },
  },
  data() {
    return {
      dueControl: 2,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    headers() {
      let baseHeaders = [
        {
          text: 'Project',
          value: 'project',
        },
        {
          text: 'Task Name',
          value: 'name',
        },
        { text: 'Priority', value: 'priority' },
        { text: 'Due Date', value: 'dueDate' },
        { text: 'Due Time', value: 'dueTime' },
        {
          text: 'Task Status',
          value: 'status',
        },
        { text: 'Estimate, hr(s)', value: 'estimate' },
        {
          text: 'Time Logged',
          value: 'fullLogs',
          sortable: false,
        },
        {
          text: 'Log Work',
          value: 'id',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ];

      if (this.isAdminMode)
        baseHeaders.splice(5, 0, {
          text: 'Assignee',
          value: 'assignee',
          sortable: false,
        });

      return baseHeaders;
    },
    filteredData() {
      let retVal = [...this.tableData];

      if (this.filterPaymentMonth)
        retVal = retVal.filter((el) =>
          el.fullLogs.some(
            (log) =>
              this.$moment(log.date, 'YYYY-MM-DD').format('MM') ===
              this.$moment(this.filterPaymentMonth, 'MMMM').format('MM')
          )
        );

      if (this.filterPaymentYear)
        retVal = retVal.filter((el) =>
          el.fullLogs.some(
            (log) =>
              this.$moment(log.date, 'YYYY-MM-DD').format('YYYY') ===
              this.filterPaymentYear
          )
        );

      if (this.filterProject)
        retVal = retVal.filter((el) => el.project === this.filterProject);

      if (this.filterDeliveryDate)
        retVal = retVal.filter((el) => el.dueDate === this.filterDeliveryDate);

      if (this.filterPerson)
        retVal = retVal.filter((el) => el.assignee.id === this.filterPerson.id);

      if (this.filterStatus)
        retVal = retVal.filter((el) => el.status === this.filterStatus);

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: Object.keys(this.tableData[0]),
      };

      if (this.filterSearch) {
        const fuse = new Fuse(retVal, searchOptions);
        retVal = fuse.search(this.filterSearch).map((el) => el.item);
      } else retVal = retVal;

      return retVal;
    },
    dueText() {
      return this.isAdminMode ? 'Delivery Date: ' : 'Your Due Date: ';
    },
  },
  methods: {
    formatDate(data) {
      return data ? this.$moment(data).format('MMM DD, YYYY') : 'TBD';
    },
    ellipseText(text, length) {
      return text?.length > length ? text.substring(0, length) + '...' : text;
    },
    handleDueAlert(item) {
      const assgnDue = this.$moment(
          item.dueDate?.replace(/-/g, '/') + ' ' + item.dueTime
        ).format(),
        now = this.$moment(new Date()).format(),
        dueMeter = this.$moment(assgnDue).diff(this.$moment(now), 'hour', true);
      if (
        ((dueMeter <= this.dueControl && dueMeter > 0) || dueMeter == 0) &&
        item.status !== 'Done'
      )
        return 'upcoming-due';
      else if (dueMeter < 0 && item.status !== 'Done') return 'overdue';
      else return '';
    },
    handleDuesStats(group) {
      const groupItems = this.tableData.filter((el) => el.dueDate === group),
        overdueItems = [],
        upcomingDueItems = [];
      groupItems.forEach((el) => {
        if (this.handleDueAlert(el) === 'overdue') overdueItems.push(el);
        else if (this.handleDueAlert(el) === 'upcoming-due')
          upcomingDueItems.push(el);
      });
      return `${
        (overdueItems.length ? 'Overdued tasks: ' + overdueItems.length : '') +
        (overdueItems.length && upcomingDueItems.length ? ' / ' : '') +
        (upcomingDueItems.length
          ? `Tasks with upcoming due in ${this.dueControl} hrs: ` +
            upcomingDueItems.length
          : '')
      }`;
    },
    updateTableDataStatus(item, value) {
      const index = this.tableData.findIndex((el) => el.id === item.id);
      this.tableData[index].status = value;
    },
    handleEditForm(item) {
      if (this.isAdminMode) this.$emit('edit-task', item);
    },
    getTimeUsed(item) {
      const logged = item.fullLogs.reduce((a, b) => +a + +(b.time || 0), 0);
      return item.estimate
        ? ((logged / item.estimate) * 100).toFixed(0)
        : logged;
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #1e34b2 !important;
  color: #fff !important;
}

::v-deep {
  .v-data-table.row-click tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
  .v-badge--icon .v-badge__badge {
    min-width: 23px;
    height: 23px;
    display: flex;
    border-radius: 50%;
    padding: 0;
    & .v-icon {
      font-size: 17px;
      height: auto;
      margin: auto;
    }
  }
}

.d-inline {
  vertical-align: middle;
}
</style>
