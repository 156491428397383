import Vue from "vue";
import Vuex from "vuex";

import { authModule } from "@/store/authStore.js";
import { assignmentModule } from "@/store/assignmentStore.js";
import { flashMessageModule } from "@/store/flashMessageStore.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      namespaced: true,
      ...authModule,
    },
    assignment: {
      namespaced: true,
      ...assignmentModule,
    },
    flashMessage: {
      namespaced: true,
      ...flashMessageModule,
    },
  },
});
