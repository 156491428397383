<template>
  <v-data-table
    :class="{ laptop, laptopSmaller }"
    :headers="headers"
    :items="filteredData"
    item-key="id"
    sort-by="firstName"
    :header-props="{ sortIcon: null }"
    :items-per-page="50"
    :footer-props="{
      'items-per-page-options': [10, 50, 100],
    }"
    @click:row="$emit('call-edit-form', $event)"
  >
    <!-- Name cell override -->
    <template v-slot:[`item.firstName`]="{ item }">
      {{ `${item.firstName} ${item.lastName}` }}
    </template>

    <!-- Inquiry Email cell override -->
    <template v-slot:[`item.inquiryEmail`]="{ value }">
      <v-btn
        fab
        x-small
        outlined
        elevation="1"
        color="accent"
        :disabled="!value"
        @click.stop="sendEmail(value)"
        ><v-icon>mdi-email-plus-outline</v-icon></v-btn
      >
    </template>

    <!-- Skills cell override -->
    <template v-slot:[`item.skills`]="{ value }">
      {{ value.join(', ') }}
    </template>

    <!-- LinkedIn cell override -->
    <template v-slot:[`item.linkedIn`]="{ value }">
      <v-btn
        icon
        large
        color="blue darken-2"
        :disabled="!value"
        @click.stop="openLinkedInProfile(value)"
        ><v-icon>mdi-linkedin</v-icon></v-btn
      >
    </template>

    <!-- CV cell override -->
    <template v-slot:[`item.cvRef`]="{ item, value }">
      <v-btn
        icon
        large
        color="secondary"
        :disabled="!value"
        :loading="item.cvDownloading"
        @click.stop="downloadCv(item)"
        ><v-icon>mdi-file-download</v-icon></v-btn
      >
    </template>

    <!-- Status cell override -->
    <template v-slot:[`item.status`]="{ value }">
      <v-chip v-if="value" :color="statusesColors[value]">{{ value }}</v-chip>
    </template>
  </v-data-table>
</template>

<script>
// libraries
import Fuse from 'fuse.js';
import { saveAs } from 'file-saver';
// internal
import { customBreakpoint } from '@/utils/mixins';
import { getFileBlob } from '@/utils/dbUtils';

export default {
  name: 'SourcingTable',
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterSpecialty: {
      type: String,
      required: false,
    },
    filterExperience: {
      type: String,
      required: false,
    },
    filterTechLevel: {
      type: String,
      required: false,
    },
    filterSkills: {
      type: Array,
      required: false,
    },
    filterStatus: {
      type: String,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'firstName' },
        { text: 'Specialty', value: 'specialty' },
        { text: 'Years of Experience', value: 'experience' },
        { text: 'Tech Level', value: 'techLevel' },
        { text: 'Skills', value: 'skills' },
        { text: 'English', value: 'eng' },
        { text: 'LinkedIn', value: 'linkedIn' },
        { text: 'CV', value: 'cvRef' },
        { text: 'Status', value: 'status' },
      ],
      statusesColors: {
        Sourced: '#fff',
        'Screening Scheduled': '#E1F5FE',
        'Screening Passed': '#B3E5FC',
        'Tech Interview Scheduled': '#E8EAF6',
        'Tech Interview Passed': '#C5CAE9',
        Negotiating: '#F0F4C3',
        Rejected: '#EF9A9A',
        Hired: '#C8E6C9',
      },
    };
  },
  mixins: [customBreakpoint],
  computed: {
    tableFieldValues() {
      if (this.tableData.length) {
        const fields = Object.entries(this.tableData[0]).reduce(
          (fields, entry) => {
            const nestedKey = entry[1]
              ? Object.keys(entry[1]).map(
                  (key) => `${entry[0]}${isNaN(key) ? `.${key}` : ''}`
                )
              : [];
            return [...fields, ...nestedKey];
          },
          []
        );
        return fields;
      } else {
        return [];
      }
    },
    filteredData() {
      let dateFilteredData = this.tableData;

      if (this.filterSpecialty)
        dateFilteredData = dateFilteredData.filter(
          (el) => el.specialty === this.filterSpecialty
        );

      if (this.filterExperience)
        dateFilteredData = dateFilteredData.filter(
          (el) => el.experience === this.filterExperience
        );

      if (this.filterTechLevel)
        dateFilteredData = dateFilteredData.filter(
          (el) => el.techLevel === this.filterTechLevel
        );

      if (this.filterSkills.length)
        dateFilteredData = dateFilteredData.filter((el) =>
          this.filterSkills.every((s) => el.skills.includes(s))
        );

      if (this.filterStatus)
        dateFilteredData = dateFilteredData.filter(
          (el) => el.status === this.filterStatus
        );

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
  },
  methods: {
    openLinkedInProfile(link) {
      window.open(link, '_blank');
    },
    downloadCv(item) {
      const index = this.tableData.indexOf(item);
      this.tableData[index].cvDownloading = true;
      getFileBlob(`${item.id}_cvFile`).then((blob) => {
        const newBlob = new Blob([blob], {
          type: 'application/pdf',
        });
        this.tableData[index].cvDownloading = false;
        saveAs(newBlob, `${item.firstName} ${item.lastName}'s CV`);
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  tbody > tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
  }
}
</style>
