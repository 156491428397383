<template>
  <div>
    <v-select
      class="caption status-selector"
      :style="{
        backgroundColor: statusColor,
      }"
      :items="statusOptions"
      item-text="value"
      dense
      hide-details
      outlined
      :value="currentStatus"
      :loading="updateProcessing"
      @change="(selected) => onChange(selected)"
      @click.native.stop.prevent
    />
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
// internal
import { processDocument, TASKS_COLLECTION_NAME } from '@/utils/dbUtils';

export default {
  name: 'ADStatusSelector',
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStatus: null,
      updateProcessing: false,
    };
  },
  computed: {
    ...mapGetters('assignment', ['statusOptions']),
    statusColor() {
      return this.statusOptions.find((el) => el.value === this.currentStatus)
        ?.color;
    },
  },
  watch: {
    value(newValue) {
      this.currentStatus = newValue;
    },
  },
  mounted() {
    this.currentStatus = this.value;
  },
  methods: {
    ...mapActions('flashMessage', ['handleFlash']),
    async onChange(selected) {
      this.currentStatus = selected;
      this.updateProcessing = true;
      await processDocument({
        document: { id: this.item.id, status: selected },
        collectionName: TASKS_COLLECTION_NAME,
      });
      this.$emit('refresh-table-data', this.item, selected);
      this.updateProcessing = false;
    },
    allowedToSelectStatuses() {
      let allowedStatuses = [...this[`${this.roleMode}Statuses`]];
      if (this.roleMode === 'writer') {
        if (this.currentStatus === 'Assigned') allowedStatuses.splice(1, 1);
        if (this.currentStatus === 'Rejected') allowedStatuses.splice(0, 1);
      }
      return allowedStatuses;
    },
    allowedToUpdateStatuses() {
      const allowedStatuses = this[`${this.roleMode}Statuses`].filter(
        (el) => el != this[`${this.roleMode}Statuses`].slice(-1)
      );
      return this.roleMode !== 'reviewer'
        ? allowedStatuses
        : this[`${this.roleMode}Statuses`];
    },
  },
};
</script>

<style lang="scss" scoped>
.status-selector {
  width: 150px;
  margin: 0 auto;
}
</style>
