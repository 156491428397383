export const flashMessageModule = {
  state: () => ({
    showFlashMsg: false,
    success: false,
    flashMsgTxt: null,
  }),
  mutations: {
    HANDLE_FLASH(state, { message, success, show }) {
      state.success = success;
      state.flashMsgTxt = message;
      state.showFlashMsg = show;
    },
  },
  actions: {
    handleFlash(context, props) {
      context.commit("HANDLE_FLASH", props);
    },
  },
  getters: {
    showFlashMsg: (state) => {
      return state.showFlashMsg;
    },
    flashMsgTxt: (state) => {
      return state.flashMsgTxt;
    },
    success: (state) => {
      return state.success;
    },
  },
};
